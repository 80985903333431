import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, inject, OnInit, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TemplateRuleTypes } from '@shared/best-practice/types/rules-types.enum';
import {
  BpTemplateType,
  EditableValueKeyPair,
  ExactRules,
  TemplateRuleFormCondition,
  TemplateRulePayloadCondition,
  TemplateRuleType
} from '@shared/best-practice/types/rules.type';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { GOVERNANCE_ENTITIES } from '@shared/governance/constants/governance-entity.const';
import { ICustomConditions, IGeneralInfoForm } from '@shared/governance/interfaces/best-practice-rule.interface';
import { SaveRuleParams } from '@shared/governance/interfaces/save-rule-params.interface';
import { GovernanceService } from '@shared/governance/services/governance.service';
import { Platform } from '@shared/platforms/types/platform.type';

@Component({
  selector: 'app-best-practices-template-dialog',
  templateUrl: './best-practices-template-dialog.component.html',
  styleUrls: ['./best-practices-template-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BestPracticesTemplateDialogComponent implements OnInit {
  templateSaved = output<void>();
  public data: {
    selectedPlatform: Platform;
    bpTemplate: BpTemplateType;
  } = inject(MAT_DIALOG_DATA);
  private fb = inject(UntypedFormBuilder);
  private cdr = inject(ChangeDetectorRef);
  private __clientFacade = inject(ClientFacade);
  private dialogRef = inject(MatDialogRef);
  private __governanceService = inject(GovernanceService);
  private __destroyRef = inject(DestroyRef);

  @HostBinding('class.dialog__container') readonly hostPopupClass = true;

  selectedPlatform = this.data.selectedPlatform;
  bpTemplate = this.data.bpTemplate;
  ruleFormGroup!: FormGroup;
  active = true;
  templateEkgBrandSafeInfo = { sum: 100, msg: '' };
  templateRuleTypes = TemplateRuleTypes;
  conditionFields: ExactRules[] = ['if_condition', 'logic_operator', 'field', 'level', 'operator', 'value', 'value_type'];
  platform!: Platform;
  defaultRuleName = 'New rule';
  allCheckboxesUnchecked = false;

  get conditions(): FormArray {
    return this.ruleFormGroup.controls.conditions as FormArray;
  }

  get generalInfo(): AbstractControl<IGeneralInfoForm> {
    return this.ruleFormGroup.get('generalInfo');
  }

  get headerInfo(): AbstractControl {
    return this.ruleFormGroup.get('headerInfo');
  }

  ngOnInit(): void {
    this.initForm();
  }

  protected _close(): void {
    this.dialogRef.close();
  }

  initForm(): void {
    this.ruleFormGroup = this.fb.group({
      headerInfo: this.fb.group({
        levels: [this.bpTemplate.levels, Validators.required],
        is_active: [this.bpTemplate.is_active ? this.bpTemplate.is_active : true]
      }),
      generalInfo: this.fb.group({
        name: [this.bpTemplate.name ? this.bpTemplate.name : this.defaultRuleName, [Validators.required, Validators.minLength(2)]],
        platform: [this.selectedPlatform.name, Validators.required],
        description: [this.bpTemplate.description ? this.bpTemplate.description : '']
      }),
      conditions: this.fb.array([])
    });
    this.initConditions();
    this.conditions.valueChanges.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((values) => {
      this.allCheckboxesUnchecked = values.every((condition: { value: boolean }) => condition.value === false);
    });
  }

  initConditions(): void {
    this.bpTemplate.rule_conditions?.map((condition) => {
      const conditionValues: { [key: string]: UntypedFormControl | UntypedFormGroup } = {};
      const ifConditionValues: { [key: string]: UntypedFormControl } = {};
      this.conditionFields.forEach((field) => {
        if (condition[field] && field !== 'if_condition') {
          if (field !== 'value_type') {
            conditionValues[field] = new UntypedFormControl((condition[field] as EditableValueKeyPair).value, [
              Validators.pattern((condition[field] as EditableValueKeyPair).pattern as RegExp)
            ]);
            if ((condition[field] as EditableValueKeyPair).editable && this.bpTemplate.type !== TemplateRuleTypes.RECOMMENDATION) {
              conditionValues[field].addValidators(Validators.required);
            }
          } else if (field === 'value_type') {
            conditionValues[field] = new UntypedFormControl((condition[field] as EditableValueKeyPair).value, [Validators.required]);
          }
        } else if (field === 'if_condition' && condition.if_condition) {
          this.conditionFields.forEach((ifField) => {
            if (ifField !== 'if_condition' && ifField !== 'logic_operator' && condition.if_condition[ifField]) {
              ifConditionValues[ifField] = new UntypedFormControl((condition.if_condition[ifField] as EditableValueKeyPair).value, [
                Validators.pattern((condition.if_condition[ifField] as EditableValueKeyPair).pattern as RegExp)
              ]);
              if (
                (condition.if_condition[ifField] as EditableValueKeyPair).editable &&
                this.bpTemplate.type !== TemplateRuleTypes.RECOMMENDATION
              ) {
                ifConditionValues[ifField].addValidators(Validators.required);
              }
            }
          });
          conditionValues[field] = this.fb.group(ifConditionValues);
        }
      });
      const fb = this.fb.group(conditionValues);
      this.conditions.push(fb);
    });
    this.cdr.detectChanges();
  }

  setActive(event: MatSlideToggleChange): void {
    this.headerInfo.get('is_active').setValue(event.checked);
  }

  cancelCreation(event: Event): void {
    event.preventDefault();
    this.dialogRef.close('cancel');
  }

  private convertConditionsValuesToString(conditions: FormArray): TemplateRulePayloadCondition[] {
    return conditions.value.map((condition: ICustomConditions) => ({ ...condition, values: condition.values }));
  }
  protected adjustInstructions(instructions: string): string {
    return instructions?.replace('client_slug', this.__clientFacade.selectedClient.slug);
  }

  mapFormToPayloadCondition(condition: TemplateRuleFormCondition): TemplateRulePayloadCondition {
    return {
      level: condition.level,
      value_type: condition.value_type,
      operator: condition.operator,
      values: condition.value ? [String(condition.value)] : [],
      if_condition: condition.if_condition ? this.mapFormToPayloadCondition(condition.if_condition) : undefined,
      logic_operator: condition.logic_operator,
      field: condition.field
    };
  }

  handleSave(): void {
    let conditionsValue: TemplateRulePayloadCondition[];
    if (this.bpTemplate.type === TemplateRuleTypes.RECOMMENDATION) {
      conditionsValue = this.convertConditionsValuesToString(this.conditions);
    }
    const rule: TemplateRuleType = {
      name: this.generalInfo.getRawValue().name,
      platform: this.selectedPlatform.name,
      levels: this.headerInfo.getRawValue().levels,
      description: this.generalInfo.getRawValue().description,
      rule_conditions: conditionsValue
        ? conditionsValue
        : this.conditions.value.map((condition: TemplateRuleFormCondition) => this.mapFormToPayloadCondition(condition)),
      is_active: this.headerInfo.getRawValue().is_active,
      rule_template_id: this.bpTemplate.id || null,
      type: this.bpTemplate.isManual ? 'manual' : this.bpTemplate.type,
      business_unit_id: this.__clientFacade.selectedBusinessUnit?.id
    };
    const params: SaveRuleParams = {
      governanceEntity: GOVERNANCE_ENTITIES[0],
      formGroup: this.ruleFormGroup,
      ruleId: null,
      payload: this.__stringifyBoolValues(rule),
      newRuleCb: null
    };
    this.__governanceService.saveRule(params).subscribe(() => {
      this.templateSaved.emit();
      this.dialogRef.close('save');
    });
  }

  private __stringifyBoolValues(rule: TemplateRuleType): TemplateRuleType {
    return {
      ...rule,
      rule_conditions: rule.rule_conditions.map((condition) => {
        if (typeof condition.value === 'boolean') {
          condition.value = String(condition.value);
        }
        return condition;
      })
    };
  }
}
