<div class="wrapper">
  <ng-container *ngIf="templateRule.type === templateRuleTypes.RECOMMENDATION">
    <div class="checkboxes-title">Select the recommandations you'd like OCTRA to suggest daily :</div>
    <mat-checkbox
      class="mb-15"
      color="warn"
      [disabled]="conditions.disabled"
      [checked]="areAllCheckboxesTrue"
      (change)="selectAllCheckboxes($event)"
    >
      Select all</mat-checkbox
    >
  </ng-container>

  <div
    class="field-container value-inputs"
    *ngIf="conditions.length"
    [ngClass]="templateRule.type === templateRuleTypes.RECOMMENDATION ? 'col-direction' : ''"
  >
    <ng-container class="value-input" *ngFor="let condition of conditions.controls; let index = index" [formGroup]="toFormGroup(condition)">
      <ng-container *ngIf="templateRule.type !== templateRuleTypes.RECOMMENDATION">
        <div class="value-input" *ngFor="let conditionField of getEditableConditions(index, false)">
          <ng-container *ngIf="conditionField !== 'if_condition'">
            <div class="label-wrapper d-flex justify-content-start align-items-center">
              <p class="label">{{ getValueLabel(conditionField, index, false) }}</p>
              <mat-icon
                class="outlined"
                [matTooltip]="getValueDetails(conditionField, index, false)"
                *ngIf="!!getValueDetails(conditionField, index, false)"
                >info</mat-icon
              >
            </div>
            <div *ngIf="!getEditableValueKeyPairType(index, conditionField, false)?.possible_values">
              <input
                formControlName="{{ conditionField }}"
                (input)="templateRule.type === templateRuleTypes.EKG_BRAND_SAFE && templateEkgBrandSafeChecks()"
                type="text"
                #input
                data-lpignore="true"
              />
              <div
                *ngIf="
                  getFormControl(conditionField, toFormGroup(condition))?.errors &&
                  getFormControl(conditionField, toFormGroup(condition))?.dirty
                "
                class="floating-error-message"
              >
                <span *ngIf="conditionField === 'value_type'">
                  {{ '*required field' }}
                </span>
                <span *ngIf="conditionField !== 'value_type'">
                  {{ input.value ? '*expecting a number (e.g. 50)' : '*required field' }}
                </span>
              </div>
            </div>
            <ng-container *ngIf="getEditableValueKeyPairType(index, conditionField, false)?.possible_values">
              <mat-form-field appearance="outline" color="warn">
                <mat-select formControlName="{{ conditionField }}" placeholder="Select value" required>
                  <mat-option
                    *ngFor="let value of getEditableValueKeyPairType(index, conditionField, false)?.possible_values"
                    [value]="value"
                  >
                    {{ value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="conditionField === 'if_condition'">
            <ng-container class="value-input" [formGroup]="toFormGroup(toFormGroup(condition).get('if_condition'))">
              <div class="value-input" *ngFor="let conditionIfField of getEditableConditions(index, true)">
                <div class="label-wrapper d-flex justify-content-start align-items-center">
                  <p class="label">{{ getValueLabel(conditionIfField, index, true) }}</p>
                  <mat-icon
                    class="outlined"
                    [matTooltip]="getValueDetails(conditionIfField, index, true)"
                    *ngIf="!!getValueDetails(conditionIfField, index, true)"
                    >info</mat-icon
                  >
                </div>
                <div *ngIf="!getEditableValueKeyPairType(index, conditionIfField, true)?.possible_values">
                  <input
                    formControlName="{{ conditionIfField }}"
                    (input)="templateRule.type === templateRuleTypes.EKG_BRAND_SAFE && templateEkgBrandSafeChecks()"
                    type="text"
                    #input
                    data-lpignore="true"
                  />
                  <!--                      <div-->
                  <!--                        *ngIf="-->
                  <!--                getFormControl(conditionField, toFormGroup(condition))?.errors &&-->
                  <!--                getFormControl(conditionField, toFormGroup(condition))?.dirty-->
                  <!--              "-->
                  <!--                        class="floating-error-message"-->
                  <!--                      >-->
                  <!--                        {{ input.value ? '*expecting a number (e.g. 50)' : '*required field' }}-->
                  <!--                      </div>-->
                </div>
                <ng-container *ngIf="getEditableValueKeyPairType(index, conditionIfField, true)?.possible_values">
                  <mat-form-field appearance="outline" color="warn">
                    <mat-select formControlName="{{ conditionIfField }}" placeholder="Select value" required>
                      <mat-option
                        *ngFor="let value of getEditableValueKeyPairType(index, conditionIfField, true)?.possible_values"
                        [value]="value"
                      >
                        {{ value }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="templateRule.type === templateRuleTypes.RECOMMENDATION">
        <div *ngFor="let conditionField of getEditableConditions(index, false)">
          <mat-checkbox [formControlName]="conditionField" (change)="areAllCheckboxesTrueInitially()" color="warn">{{
            getValueLabel(conditionField, index, false)
          }}</mat-checkbox>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div *ngIf="templateRule.type === templateRuleTypes.EKG_BRAND_SAFE && templateEkgBrandSafeInfo.sum !== 100" class="error-message">
    {{ templateEkgBrandSafeInfo.msg }}
  </div>
</div>
