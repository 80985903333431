import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TemplateRuleTypes } from '@shared/best-practice/types/rules-types.enum';
import { EditableValueKeyPair, ExactRules, BpTemplateType, TemplateRuleConditions } from '@shared/best-practice/types/rules.type';

@Component({
  selector: 'app-best-practices-template-dialog-inputs',
  templateUrl: './best-practices-template-dialog-inputs.component.html',
  styleUrls: ['./best-practices-template-dialog-inputs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BestPracticesTemplateDialogInputsComponent implements OnInit {
  @Input() templateRule!: BpTemplateType;
  @Input() conditions!: FormArray;
  @Input() isNew!: boolean;
  templateEkgBrandSafeInfo = { sum: 100, msg: '' };
  templateRuleTypes = TemplateRuleTypes;
  areAllCheckboxesTrue = true;
  constructor() {}

  get ruleConditionGroups(): TemplateRuleConditions[] {
    return this.templateRule.rule_conditions as TemplateRuleConditions[];
  }

  ngOnInit(): void {
    if (this.templateRule.type === TemplateRuleTypes.RECOMMENDATION) {
      this.convertToBoolean();
    }

    if (this.templateRule.type === TemplateRuleTypes.EKG_BRAND_SAFE) {
      if (this.isNew) {
        this.setDefaultSettingsForTemplateEkgBrandSafe();
      }
      this.templateEkgBrandSafeChecks();
    }
    this.areAllCheckboxesTrueInitially();
  }

  getFormControl(name: string, formGroup: FormGroup): UntypedFormControl {
    return formGroup.controls[name] as UntypedFormControl;
  }

  toFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  getValueLabel(conditionField: ExactRules, index: number, isIf: boolean): string {
    if (!this.templateRule.rule_conditions) {
      return '';
    }
    if (isIf && conditionField !== 'if_condition') {
      return (this.templateRule.rule_conditions[index].if_condition[conditionField] as EditableValueKeyPair).label || '';
    } else {
      return (this.templateRule.rule_conditions[index][conditionField] as EditableValueKeyPair).label || '';
    }
  }
  getValueDetails(conditionField: ExactRules, index: number, isIf: boolean): string {
    if (!this.templateRule.rule_conditions) {
      return '';
    }
    if (isIf && conditionField !== 'if_condition') {
      return (this.templateRule.rule_conditions[index].if_condition[conditionField] as EditableValueKeyPair).details || '';
    } else {
      return (this.templateRule.rule_conditions[index][conditionField] as EditableValueKeyPair).details || '';
    }
  }

  getEditableConditions(index: number, isIf: boolean): ExactRules[] {
    let conditions = this.ruleConditionGroups[index];
    if (isIf && conditions.if_condition) {
      conditions = conditions.if_condition;
    }
    const editableConditions: ExactRules[] = [];
    Object.keys(conditions).map((conditionField) => {
      if (conditionField === 'if_condition' && conditions.if_condition) {
        editableConditions.push(conditionField as ExactRules);
      }
      const condition = this.getEditableValueKeyPairType(index, conditionField as ExactRules, isIf);
      if (condition?.editable) {
        editableConditions.push(conditionField as ExactRules);
      }
    });
    return editableConditions;
  }

  selectAllCheckboxes(event: MatCheckboxChange): void {
    this.conditions.controls.map((condition: AbstractControl) => {
      condition.get('value')?.setValue(event.checked);
    });
    this.conditions.markAsDirty();
  }

  areAllCheckboxesTrueInitially(): void {
    this.areAllCheckboxesTrue = !this.conditions.controls.find((condition: AbstractControl) => condition.get('value').value === false);
  }

  getEditableValueKeyPairType(index: number, controlName: ExactRules, isIf: boolean): EditableValueKeyPair | null {
    if (this.templateRule.rule_conditions && isIf && controlName !== 'if_condition') {
      return this.templateRule.rule_conditions[index].if_condition[controlName] as EditableValueKeyPair;
    } else if (this.templateRule.rule_conditions) {
      return this.templateRule.rule_conditions[index][controlName] as EditableValueKeyPair;
    } else {
      return null;
    }
  }

  templateEkgBrandSafeChecks(): void {
    let templateTotalSum = 0;
    this.conditions.controls.map((condition) => {
      if (condition.get('field')?.value !== 'impressions') {
        templateTotalSum += Number(condition.get('value')?.value);
      }
    });

    if (templateTotalSum !== 100) {
      this.conditions.controls[0].setErrors({ sum: true });
    } else {
      this.conditions.controls[0].setErrors(null);
    }
    const msg = `Weigthings should sum up to 100% (currently ${templateTotalSum}%)`;
    this.templateEkgBrandSafeInfo = { sum: templateTotalSum, msg };
  }

  private convertToBoolean(): void {
    this.conditions.controls.map((condition) => {
      if (condition.get('value')?.value === 'false') {
        condition.get('value')?.setValue(false);
      }
      if (condition.get('value')?.value === 'true') {
        condition.get('value')?.setValue(true);
      }
    });
  }

  private setDefaultSettingsForTemplateEkgBrandSafe(): void {
    this.templateRule.rule_conditions?.map((condition, index) => {
      if ((condition.value as EditableValueKeyPair).label?.toLowerCase().includes('weighting')) {
        const defaultValue = index <= 10 ? '7' : '6';
        this.conditions.controls[index].get('value')?.setValue(defaultValue);
      }
    });
  }
}
