<div class="title"></div>
<div class="content">
  <form (ngSubmit)="handleSave()">
    <div *ngIf="ruleFormGroup" [formGroup]="ruleFormGroup">
      <div class="header">
        <div class="header-left">
          <span class="font-weight-500 fs-16 lh-24">Levels: </span>
          <span>{{ headerInfo.get('levels').value?.join(', ') }}</span>
        </div>
        <div class="header-right" aria-label="toggle active">
          <span>Active</span>
          <mat-slide-toggle (change)="setActive($event)" [checked]="headerInfo.get('is_active').value" />
        </div>
      </div>

      <app-general-form formGroupName="generalInfo" [modalView]="true" />
      <app-best-practices-template-dialog-inputs
        *ngIf="bpTemplate && conditions.length"
        [templateRule]="bpTemplate"
        [conditions]="conditions"
        [isNew]="true"
      />
      <div *ngIf="allCheckboxesUnchecked" class="mt-8 error-message">You have to choose at least one condition.</div>
      <div [innerHTML]="adjustInstructions(bpTemplate.instructions)" class="px-24"></div>
      <div class="mx-24 py-12">
        <span *ngIf="bpTemplate.type === templateRuleTypes.EKG_BRAND_SAFE && templateEkgBrandSafeInfo.sum !== 100" class="error-message">
          {{ templateEkgBrandSafeInfo.msg }}
        </span>
      </div>
    </div>
  </form>
</div>
<div class="buttons">
  <button mat-stroked-button color="outline" (click)="cancelCreation($event)">Cancel</button>
  <button
    mat-raised-button
    class="ms-auto"
    [disabled]="templateEkgBrandSafeInfo.sum !== 100 || ruleFormGroup.invalid || allCheckboxesUnchecked"
    (click)="handleSave()"
  >
    Save
  </button>
</div>

<button type="button" mat-icon-button class="dialog__close" (click)="_close()">
  <mat-icon>close</mat-icon>
</button>
